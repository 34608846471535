import React from "react";

export default function AppleIcon() {
  return (
    <svg
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6308 18.6111C20.5635 18.801 20.4984 19.0049 20.4234 19.2053C19.8025 20.8864 18.86 22.379 17.7164 23.7469C17.4957 24.0132 17.2385 24.2542 16.9729 24.4778C16.448 24.9221 15.844 25.1904 15.1453 25.215C14.6205 25.2318 14.1166 25.1365 13.631 24.9389C13.2792 24.7952 12.9331 24.6347 12.5764 24.5051C11.4187 24.0847 10.2674 24.14 9.12868 24.5899C8.71804 24.7525 8.31301 24.9319 7.89466 25.0727C7.40974 25.2353 6.9066 25.3012 6.39435 25.2045C5.94727 25.1211 5.55625 24.9129 5.19327 24.6446C4.74268 24.311 4.36428 23.9046 4.0104 23.4743C2.3349 21.4309 1.2102 19.1205 0.667113 16.5327C0.433063 15.4199 0.320942 14.298 0.389616 13.1606C0.470202 11.797 0.786942 10.4985 1.51572 9.32823C2.4302 7.85736 3.72029 6.89382 5.41751 6.4965C6.4385 6.25614 7.43146 6.35424 8.40481 6.72915C8.91706 6.92536 9.43071 7.11946 9.94506 7.31217C10.4188 7.49016 10.8904 7.49016 11.3648 7.30867C11.8896 7.10825 12.4152 6.90854 12.9422 6.71303C13.4825 6.51612 14.0318 6.35495 14.6051 6.3094C15.4628 6.24002 16.2995 6.35144 17.1137 6.62403C18.2679 7.01015 19.2069 7.69338 19.916 8.68845C19.935 8.71578 19.956 8.74591 19.97 8.76763C18.1817 10.0143 17.1635 11.661 17.3114 13.8908C17.4606 16.122 18.6617 17.6314 20.6308 18.6111ZM10.5996 6.0333C11.1272 6.04732 11.6346 5.94571 12.123 5.7516C14.3507 4.86725 15.5153 2.51062 15.4803 0.708291C15.4775 0.563236 15.4677 0.41818 15.4614 0.25C15.2322 0.284337 15.0234 0.300454 14.823 0.350908C13.1965 0.749636 11.945 1.65711 11.1223 3.12658C10.6409 3.98711 10.3732 4.90089 10.4552 5.89946C10.4608 6.00527 10.4994 6.0319 10.5996 6.0333Z"
        fill="#007AFF"
      />
    </svg>
  );
}
